import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Directory() {
  const [galleries, setGalleries] = useState([]);

  useEffect(() => {
    axios.get('./db.json')
      .then(res => {
        setGalleries(res.data.galleries);
      });
  }, []);

  return (
    <div>
      <h1>Directory</h1>
      {galleries.map(gallery => (
        <div key={gallery.id}>
          <h2><Link to={`/gallery/${gallery.id}`}>{gallery.name}</Link></h2>
          <p>Date: {gallery.date}</p>
          <p>Location: {gallery.location}</p>
          <p>Instrument: {gallery.instrument}</p>
        </div>
      ))}
    </div>
  );
}

export default Directory;
