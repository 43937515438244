import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function Gallery() {
  const { id } = useParams();
  const [gallery, setGallery] = useState(null);

  useEffect(() => {
    axios.get('/db.json')
      .then(res => {
        const foundGallery = res.data.galleries.find(gallery => gallery.id === parseInt(id));
        setGallery(foundGallery);
      });
  }, [id]);

  // If gallery hasn't been loaded yet, show a loading message
  if (!gallery) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{gallery.name}</h1>
      {gallery.photos.map(photo => (
        <div key={photo.id}>
          <img src={photo.src} alt={photo.alt} />
        </div>
      ))}
    </div>
  );
}

export default Gallery;
