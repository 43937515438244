import React, { useState } from "react";
import Slideshow from "./Slideshow";
import logo from "../images/logo.svg";
import "./Home.css";

const Home = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const images = [
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0508.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0593.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0602.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0489.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0518.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0540.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0628.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0561.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0567.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0656.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0581.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0637.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0667.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0736.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0756.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0759.jpg",
      alt: "Photo",
    },
    {
      src: "https://storage.googleapis.com/foundnone-public/TRNS0557.jpg",
      alt: "Photo",
    },

    // add more images as needed
  ];

  return (
    <div
      className={`home-container ${isHovered ? "hovered" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="logo-container"
        onClick={() => setDropdownVisible(!isDropdownVisible)}
      >
        <img src={logo} alt="Logo" className="logo" />

        {isDropdownVisible && (
          <div className="dropdown-menu">
            <a
              href="https://twitter.com/transmental"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
            <a
              href="https://xeenon.xyz/foundnone"
              target="_blank"
              rel="noopener noreferrer"
            >
              Xeenon
            </a>
            <a
              href="https://www.cryptovoxels.com/play?coords=NE@1181.5E,840S,0.5U"
              target="_blank"
              rel="noopener noreferrer"
            >
              Research
            </a>
            <a
              href="https://opensea.io/collection/foundnone-research-editions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Collect
            </a>
            <a
              href="https://ukiyo.foundnone.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ukiyo-E
            </a>
            <a
              href="https://tofushop.mypinata.cloud/ipfs/QmP9tUeHJf5kGSPMuSsasgnsbSGHiG9WC4YFpb14bHcDv4/examples/player/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Listen
            </a>
          </div>
        )}
      </div>

      <Slideshow images={images} />
      <div className="overlay">
        <h1>TOKYO KAGERU</h1>
      </div>
    </div>
  );
};

export default Home;
