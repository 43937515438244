import React, { useState, useEffect } from 'react';
import './Slideshow.css';

const Slideshow = ({ images }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((current) => (current + 1) % images.length);
    }, 6000); // change images every 3 seconds

    return () => clearInterval(timer); // cleanup on unmount
  }, [images]);

  return (
    <div className="slideshow">
      {images.map((image, i) => (
        <img
          key={i}
          src={image.src}
          alt={image.alt}
          className={`fade ${index === i ? 'active' : ''}`}
        />
      ))}
    </div>
  );
};

export default Slideshow;
